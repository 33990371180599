
import { ReactNode } from 'react';
import { DataFlat, DataStructure, DataKeys } from '../types';
// import components
import { MainFrame, SpacingFrame, Col2ContentFrame } from '../components/display/Frame';
import { ProductCard } from '../components/display/Cards';
import { TitleSubtitle, TextBody, TextBulletList, TextParagraphHeader } from '../components/display/TextFormat';
import { ImageImport, ImageImportContain, ImageImportNotSBSSmall } from '../components/display/ImageFormat';
import { VideoPlayer } from '../components/tools/Viewer';
import { SlideInAnim, SlideDirection } from '../components/tools/Animations';
// import functions
import { filterAISolutionsData } from './DataFunctions';
// import context
import { useLang, useScreenSize } from '../components/Context';
// import global var
import { MoreButton } from '../components/tools/Buttons';

interface DisplayInfoProps {
    contentData: DataStructure;
    imagePath: string;
    title?: string;
    subtitle?: string;
};
export function DisplayInfo2Cols({ contentData, imagePath, title, subtitle } : DisplayInfoProps) {
    const { lang } = useLang();
    const { screenSize } = useScreenSize();

    function createText(data: DataFlat) {
        let title = data["title"][lang];
        let text = data["text"][lang];
        let subtitle = undefined;
        let list = undefined;
        let button = undefined;
        let text2 = undefined;
        try { subtitle = data["subtitle"][lang] } catch {}
        try { list = data["list"][lang] } catch {}
        try { text2 = data["text2"][lang] } catch {}
        try { button = data["link"] } catch {}
        return (
            <SpacingFrame spacing={20}>
                <TitleSubtitle title={title} left />
                { subtitle && !list && <TextBody text={[]} title={subtitle} /> }
                { text.length !== 0 && <TextBody text={text} /> }
                { subtitle && list && <TextBulletList subtitle={subtitle} list={list} subtitleStyle="font-bold" /> }
                { !subtitle && list && <TextBulletList list={list} /> }
                { text2 && <TextBody text={text2} /> }
                { button && <MoreButton label={button["label"]} link={button["link"]} /> }
            </SpacingFrame>
        )
    };

    function createBulletList(data: DataFlat) {
        let title = data["title"][lang];
        let text = data["text"][lang];
        let textContent = data["content"];
        let subtitle = undefined;
        let subtitle2 = undefined;
        let button = undefined;
        try { subtitle = data["subtitle"][lang] } catch {}
        try { subtitle2 = data["subtitle2"][lang] } catch {}
        try { button = data["link"] } catch {}
        let notBullet = data["notBullet"];

        return (
            <SpacingFrame spacing={20}>
                <TitleSubtitle title={title} left />
                { subtitle && <TextBody text={[]} title={subtitle} /> }
                { text.length !== 0 && <TextBody text={text} /> }
                { subtitle2 && <TextBody text={[]} title={subtitle2} /> }
                { notBullet 
                    ? <TextParagraphHeader data={textContent} />
                    : <TextParagraphHeader data={textContent} isBulletList />
                }
                { button && <MoreButton label={button["label"]} link={button["link"]} /> }
            </SpacingFrame>
        )
    };

    function createImage(data: DataFlat, direction: SlideDirection) {
        let image = data["image"];
        let contained = false;
        let NotSBSSmall = false;
        let maxHeight = 580;
        try { contained = data["contained"] } catch {}
        try { maxHeight = data["maxHeight"] } catch {}
        try { NotSBSSmall = data["notSBSSmall"] } catch {}
        if (contained) {
            return (
                <SlideInAnim direction={direction}>
                    <ImageImportContain image={image} imagePath={imagePath} maxHeight={maxHeight} />
                </SlideInAnim>
            )
        } else if (NotSBSSmall) {
            return (
                <SlideInAnim direction={direction} style={{ margin: "auto" }}>
                    <ImageImportNotSBSSmall images={image} imagePath={imagePath} />
                </SlideInAnim>
            )

        } else {
            return (
                <SlideInAnim direction={direction} style={{ margin: "auto" }}>
                    <ImageImport image={image} imagePath={imagePath} />
                </SlideInAnim>
            )
        };
    };

    function createVideo(data: DataFlat) {
        let link = data["video"];
        let height = 600;
        let label = undefined;
        try { height = data["height"] } catch {}
        try { label = data["label"] } catch {}
        if (label) {
            let text = createText(label);
            return (
                <SpacingFrame spacing={20}>
                    { text }
                    <SlideInAnim direction="bottom">
                        <div className="m-auto w-full h-[100%]">
                            <VideoPlayer url={link} height={ height } />
                        </div>
                    </SlideInAnim>
                </SpacingFrame>
            )
        } else {
            return (
                <SlideInAnim direction="bottom">
                    <div className="m-auto w-full h-[100%]">
                        <VideoPlayer url={link} height={ height } />
                    </div>
                </SlideInAnim>
            )
        };
    };

    // determine type of content and return component
    function determineColType(type: string, data: DataFlat, direction: SlideDirection) {
        let content = <></>
        if (type === "text") {
            content = createText(data);
        } else if (type === "bulletList") {
            content = createBulletList(data);
        } else if (type === "image") {
            content = createImage(data, direction)
        } else if (type === "video") {
            content = createVideo(data);
        };
        return content
    };

    let content : ReactNode = <></>;
    if (contentData) {
        content = Object.keys(contentData).map((i) => {
            let index = Number(i);
            let sectionData = contentData[i];
            let left = undefined;
            let right = undefined;
            
            Object.keys(sectionData).map((s) => {
                let colData = sectionData[s];
                let colType = colData["type"];
                
                if (s === "left") {
                    left = determineColType(colType, colData, s);
                } else if (s === "right") {
                    right = determineColType(colType, colData, s);
                };                
            });
            

            if (!left) {
                return (
                    <>{ right }</>
                )
            } else if (!right) {
                return ( 
                    <>{ left }</>
                )
            } else {
                if (screenSize === "Small") {
                    if (index % 2 == 0) {
                        return (
                            <Col2ContentFrame>
                                { right }
                                { left }
                            </Col2ContentFrame>
                        )
                    } else {
                        return (
                            <Col2ContentFrame>
                                { left }
                                { right }
                            </Col2ContentFrame>
                        )
                    };
                } else {
                    return (
                        <Col2ContentFrame>
                            { left }
                            { right }
                        </Col2ContentFrame>
                    )
                }
            };
        });
    };

    return (
        <MainFrame>
            { title && 
                <div className="mb-16">
                    <TitleSubtitle title={title} subtitle={subtitle} /> 
                </div>
            }
            <SpacingFrame spacing={128}>
                { content }
            </SpacingFrame>
        </MainFrame>
    )
};


interface DisplayProductCardsProps {
    data: string[];
    haveTag?: boolean;
}
export function DisplayProductCards({ data, haveTag } : DisplayProductCardsProps) {
    const SolutionsData = filterAISolutionsData(data) as DataStructure;
    let solutionsCard = Object.keys(SolutionsData).map((i) => {
        let dataUnit = SolutionsData[i] as DataKeys;
        let imagePath = dataUnit["imagePath"];
        return (
            <ProductCard data={dataUnit} imagePath={imagePath} haveTag={haveTag} />
        )
    });

    return (
        <>
            { solutionsCard }
        </>
    )
};