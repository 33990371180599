// import images
import drugomicsLogo from "../../image/Logo_drug.svg";
import labImage from "../../image/drugomics/drugomics.jpg";
import dnaImage from "../../image/drugomics/dna-robot-hand.jpg";
import womenLabImage from "../../image/drugomics/women-lab-coat.jpeg";
import drugBanner from "../../image/drugomics/busy-chef-preparing.jpg";
import drugSecImage from "../../image/drugomics/biomedical-engineer.jpg";
import vaccineBanner from "../../image/drugomics/portrait-women-lab.jpg";
import vaccineSecImage from "../../image/drugomics/focused-female-scientist.jpg"
import medicineBanner from "../../image/drugomics/doctor-from-future.jpg";
import medicineSecImage from "../../image/drugomics/male-pharmacist.jpg";
import pipelineBanner from "../../image/drugomics/biomedical-student-working.jpg"
// import data
import contentData from "../../data/content/drugomics/drugomicsContent.json";
import servicesData from "../../data/content/drugomics/drugServices.json";
import unitContentData from "../../data/content/drugomics/drugServicesContent.json";
import pipelineContentData from "../../data/content/drugomics/drugPipelineContent.json";

import { DataKeys, DataStructure } from '../../types';
// import components
import { MainFrame, HorizontalScrollArrowsFrame, Col2ContentFrame, ColAutoFrame, SpacingFrame, Col3ContentFrame, Col4ContentFrame } from "../display/Frame";
import { MemberCard, StepsCard, LeftBorderCard, StepsCardWithList } from "../display/Cards";
import { BannerSection, MainIdeaSection, BusinessBannerSection, StatsSection } from "../display/Sections";
import { ImageBanner } from "../display/ImageFormat";
import { TitleSubtitle, CapsText, TextBody } from "../display/TextFormat";
import { MoreButton } from "../tools/Buttons";
import { NavTree } from "../navigation/NavLinkPages";
import { UpdateSummary } from "../content/BlogNews";
import DrugPipeline, { DrugDesignSection, OtherDrugServicesCard } from "../content/DrugContent";
import { NumberCounterAnim } from "../tools/Animations";
import { AtomToolsOverview } from "../content/ToolsOverview";
import { SlideInAnim } from "../tools/Animations";
// import function
import { filterPeopleData } from "../../functions/DataFunctions";
import { DisplayInfo2Cols } from "../../functions/DisplayInfoFunctions";
// import context
import { useScreenSize, useLang } from "../Context";
// import global var
import { typedVocab } from "../GlobalVar";
import { AboutDefaultBanner } from "./About/About";

const typedContent : DataStructure = contentData;
const typedServices : DataStructure = servicesData;
const typedUnitContent : DataStructure = unitContentData;
const typePipelineContent : DataStructure = pipelineContentData;
const imagePath = "image/drugomics/";

export default function Drugnomics() {
    const { screenSize } = useScreenSize();
    const { lang } = useLang();

    // extract section data
    const section1 = typedContent[1];
    const section2 = typedContent[2];
    const section3 = typedContent[3];
    const section4 = typedContent[4];
    const section5 = typedContent[5];

    // section 3: 
    let drugContent = Object.keys(section3["content"]).map((i) => {
        let data = section3["content"][i];
        return (
            <div className="flex flex-col gap-2">
                <p className="text-left text-2xl font-bold">
                    <NumberCounterAnim value={ data["number"] } />
                    <span>{ data["title"][lang] }</span>
                </p>
                <p className="text-left text-sm font-semibold">{ data["subtitle"][lang] }</p>
                <p className="text-left text-sm text-grey-400">{ data["text"][lang] }</p>
            </div>
        )
    });

    // section 6: people
    const peopleData = filterPeopleData(section5["people"]) as DataStructure;
    let membershipCards = Object.keys(peopleData).map((i) => {
        let data = peopleData[i] as DataKeys;
        return (
            <MemberCard data={data} />
        )
    });


    return (
        <>
            <BusinessBannerSection 
                bgImage={ labImage }
                logoImage={ drugomicsLogo }
                title={ section1["title"][lang] }
                subtitle={ section1["subtitle"][lang] }
            />
            
            <NavTree />

            <MainFrame style={{ backgroundColor: "rgba(162, 218, 219, 0.4)" }}>
                <CapsText text={ section2["capsText"][lang] } />
                <TitleSubtitle title={ section2["title"][lang] } />
                <DrugDesignSection />
            </MainFrame>

            <MainFrame>
                <SpacingFrame>
                    <div>
                        <CapsText text={ section3["capsText"][lang] } style="text-left text-grey-400" />
                        <TitleSubtitle title={ section3["title"][lang] } left />
                    </div>
                    <Col2ContentFrame>
                        <SlideInAnim direction="left">
                            <div className="flex items-center justify-center">
                                <img src={dnaImage} />
                            </div>
                        </SlideInAnim>
                        <div className="grid grid-flow-row auto-rows-min gap-6 text-base">
                            { drugContent }
                        </div>
                    </Col2ContentFrame>
                </SpacingFrame>
            </MainFrame>

            <AtomToolsOverview isScroll />

            <MainFrame>
                <SpacingFrame spacing={40}>
                    <DrugPipeline />
                    <div className="flex items-center justify-center">
                        <MoreButton label="View Full Pipeline" link="/drugomics/drug-design/pipeline" />
                    </div>
                </SpacingFrame>
            </MainFrame>

            <ImageBanner image={womenLabImage} />

            <DisplayInfo2Cols contentData={ section4["content"] } imagePath={imagePath} title={ section4["title"][lang] } />

            <MainFrame style={{ backgroundColor: "#F5F5F5" }}>
                <SpacingFrame>
                    <TitleSubtitle 
                        title={ section5["title"][lang] }
                    />
                    <div>
                        { screenSize === "Large" && <MoreButton label="View All Members" link="/about/team" /> }
                        <HorizontalScrollArrowsFrame>
                            { membershipCards }
                        </HorizontalScrollArrowsFrame>
                        { screenSize === "Small" && <MoreButton label="View All Members" link="/about/team" /> }
                    </div>
                </SpacingFrame>
            </MainFrame>

            <UpdateSummary />
        </>
    )
};


//////////////////////
// LOCAL FUNCTIONS //
/////////////////////
interface ExploreOtherServicesProps {
    location: string;
};
function ExploreOtherServices({ location } : ExploreOtherServicesProps) {
    const { lang } = useLang();

    let content = Object.keys(typedServices).map((i) => {
        let data = typedServices[i];
        data["key"] = i;
        if (i !== location) {
            return (
                <OtherDrugServicesCard data={data} />
            )
        };
    })
    return (
        <MainFrame style={{ backgroundColor: "rgba(162, 218, 219, 0.4)" }}>
            <SpacingFrame>
                <TitleSubtitle title={ typedVocab["Explore More Drugomics"][lang] } />
                <ColAutoFrame>
                    { content }
                </ColAutoFrame>
            </SpacingFrame>
        </MainFrame>
    )
};

//////////////////////////
// OTHER SERVICES PAGE //
/////////////////////////
export function DrugDesign() {
    const { lang } = useLang();

    const data = typedUnitContent["Drug Design"];
    const section1 = data[1];
    const section2 = data[2];
    const section3 = data[3];
    const section4 = data[4];

    // section 4: drug dev steps
    let devSteps = Object.keys(section4["content"]).map((i) => {
        let content = section4["content"][i];
        return (
            <StepsCard data={content} />
        )
    });
    
    return (
        <>
            <BannerSection image={pipelineBanner} title={ section1["title"][lang] } subtitle={ section1["caption"][lang] } textPosition="Top" />

            <NavTree />

            <MainIdeaSection text={ section2["text"][lang] } />

            <MainFrame>
                <StatsSection title={ section3["title"][lang] } subtitle={ section3["subtitle"][lang] } data={ section3["content"] } />
            </MainFrame>

            <MainFrame>
                <SpacingFrame spacing={40}>
                    <TitleSubtitle title={ section4["title"][lang] } />
                    <p>{ section4["subtitle"][lang] }</p>
                    <SlideInAnim direction="bottom">
                        <img className="p-6" src={require("../../image/drugomics/" + section4["image"])} />
                    </SlideInAnim>
                    <ColAutoFrame>
                        { devSteps }
                    </ColAutoFrame>
                </SpacingFrame>
            </MainFrame>

            <ImageBanner image={drugSecImage} />

            <MainFrame>
                <SpacingFrame spacing={40}>
                    <DrugPipeline />
                    <div className="flex items-center justify-center">
                        <MoreButton label="View Full Pipeline" link="/drugomics/drug-design/pipeline" />
                    </div>
                </SpacingFrame>
            </MainFrame>

            <ExploreOtherServices location="drug-design" />
        </>
    )
};

export function VaccineDesign() {
    const { lang } = useLang();

    const data = typedUnitContent["Vaccine Design"];
    const section1 = data[1];
    const section2 = data[2];
    const section3 = data[3];
    const section4 = data[4];

    // section 3: steps
    let devSteps = Object.keys(section3["content"]).map((i) => {
        let content = section3["content"][i];
        return (
            <StepsCard data={content} />
        )
    });

    // section 4: advantages
    let advantages = Object.keys(section4["content"]).map((i) => {
        let content = section4["content"][i];
        return (
            <LeftBorderCard data={content} />
        )
    });

    return (
        <>
            <BannerSection image={vaccineBanner} title={ section1["title"][lang] } subtitle={ section1["caption"][lang] } textPosition="Top" />

            <NavTree />

            <MainIdeaSection text={ section2["text"][lang] } />

            <MainFrame>
                <SpacingFrame spacing={40}>
                    <TitleSubtitle title={ section3["title"][lang] } />
                    <p>{ section3["subtitle"][lang] }</p>
                    <SlideInAnim direction="bottom">
                        <img className="p-6" src={require("../../image/drugomics/" + section3["image"])} />
                    </SlideInAnim>
                    <ColAutoFrame>
                        { devSteps }
                    </ColAutoFrame>
                </SpacingFrame>
            </MainFrame>

            <ImageBanner image={vaccineSecImage} />

            <MainFrame>
                <SpacingFrame spacing={40}>
                    <TitleSubtitle title={ section4["title"][lang] } />
                    <ColAutoFrame>
                        { advantages }
                    </ColAutoFrame>
                </SpacingFrame>
            </MainFrame>

            <ExploreOtherServices location="vaccine-design" />
        </>
    )
};

export function PersonalizedMedicine() {
    const { lang } = useLang();

    const data = typedUnitContent["Personalized Medicine"];
    const section1 = data[1];
    const section2 = data[2];
    const section3 = data[3];
    const section4 = data[4];

    // section 3: steps
    let devSteps = Object.keys(section3["content"]).map((i) => {
        let content = section3["content"][i];
        return (
            <StepsCard data={content} />
        )
    });

    // section 4: advantages
    let advantages = Object.keys(section4["content"]).map((i) => {
        let content = section4["content"][i];
        return (
            <LeftBorderCard data={content} />
        )
    });

    return (
        <>
            <BannerSection image={medicineBanner} title={ section1["title"][lang] } subtitle={ section1["caption"][lang] } textPosition="Top" />

            <NavTree />

            <MainIdeaSection text={ section2["text"][lang] } />

            <MainFrame>
                <SpacingFrame spacing={40}>
                    <TitleSubtitle title={ section3["title"][lang] } />
                    <p>{ section3["subtitle"][lang] }</p>
                    <SlideInAnim direction="bottom">
                        <img className="p-6" src={require("../../image/drugomics/" + section3["image"])} />
                    </SlideInAnim>
                    <ColAutoFrame>
                        { devSteps }
                    </ColAutoFrame>
                </SpacingFrame>
            </MainFrame>

            <ImageBanner image={medicineSecImage} />

            <MainFrame>
                <SpacingFrame spacing={40}>
                    <TitleSubtitle title={ section4["title"][lang] } />
                    <p>{ section4["subtitle"][lang] }</p>
                    <ColAutoFrame>
                        { advantages }
                    </ColAutoFrame>
                </SpacingFrame>
            </MainFrame>

            <ExploreOtherServices location="personalized-medicine" />
        </>
    )
};


////////////////////
// DRUG PIPELINE //
///////////////////
export function DrugomicsPipeline() {
    const { lang } = useLang();

    const data = typedUnitContent["Pipeline"];
    const section1 = data[1];
    const section2 = data[2];
    const section3 = data[3];

    // section 4: advantages
    let process = Object.keys(section3["content"]).map((i) => {
        let content = section3["content"][i];
        return (
            <LeftBorderCard data={content} isBulletList />
        )
    });

    return (
        <>
            <BannerSection image={pipelineBanner} title={ section1["title"][lang] } subtitle={ section1["caption"][lang] } textPosition="Top" />

            <NavTree />

            <MainIdeaSection text={ section2["text"][lang] } />

            <MainFrame>
                <SpacingFrame spacing={40}>
                    <TitleSubtitle title={ section3["title"][lang] } />
                    <p>{ section3["subtitle"][lang] }</p>
                    <div className="lg:grid lg:grid-cols-3 lg:gap-y-10 gap-6 sm:flex sm:flex-col">
                        { process }
                    </div>
                </SpacingFrame>
            </MainFrame>

            <MainFrame>
                <DrugPipeline showAll />
            </MainFrame>
        </>
    )
};


export function DrugPipelineContent() {
    const { lang } = useLang();

    //const data = typedUnitContent["Drug Design"]; 
    const data = typePipelineContent["osteoarthritis"];
    const section1 = data[1];
    const section2 = data[2];
    const section3 = data[3];
    const section4 = data[4];
    const section5 = data[5];
    const section6 = data[6];
    const section7 = data[7];
    const section8 = data[8];
    const section9 = data[9];
    const section10 = data[10];

    let osteoarthritis_key_risk_factors = Object.keys(section3["content"]).map((i) => {
        let data = section3["content"][i];
        let image = require("../../image/icons/" + data["icon"]);
        return (
            <div className="w-full h-fit flex flex-col items-center justify-center gap-8">
                <div className="relative w-20 h-20 rounded-full bg-teal-500">
                    <div className="absolute w-20 h-20 flex items-center justify-center">
                        <img className="w-10 h-10" src={ image } />
                    </div>
                </div>
                <SpacingFrame spacing={12}>
                    <p className="text-md font-semibold">{ data["title"][lang] }</p>
                    <p className="text-sm text-grey-400">{ data["subtitle"][lang] }</p>
                </SpacingFrame>
            </div>
        )
    });


    // section 6
    let devStepsSection6 = Object.keys(section6["content"]).map((i) => {
        let content = section6["content"][i];
        return (
            <StepsCardWithList data={content} imagePath="image/drugomics/" />
        )
    });

    // section 7
    let devStepsSection7 = Object.keys(section7["content"]).map((i) => {
        let content = section7["content"][i];
        return (
            <StepsCardWithList data={content} imagePath="image/drugomics/" />
        )
    });
    
    return (

        <>
      
            <BannerSection image={pipelineBanner} title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } textPosition="Top" />
            
            <NavTree />

            <MainIdeaSection text={ section1["text"][lang] } />

            <MainFrame>
                <StatsSection title={ section2["title"][lang] } subtitle={ section2["subtitle"][lang] } data={ section2["content"] } />
            </MainFrame>

            {/* <MainFrame style={{ backgroundColor: "rgba(248, 249, 250, 1)", overflow: "hidden" }}>
                <SpacingFrame>
                    <TitleSubtitle title={ section3["title"][lang] } subtitle={ section3["subtitle"][lang] } />
                    <SlideInAnim direction="bottom">
                        <Col4ContentFrame>
                           {osteoarthritis_key_risk_factors}
                        </Col4ContentFrame>
                    </SlideInAnim>
                </SpacingFrame>
            </MainFrame> */}

            <DisplayInfo2Cols contentData={section4["content"]} imagePath={imagePath} />

            <MainIdeaSection text={ section5["text"][lang] } />

            <MainFrame>
                <SpacingFrame spacing={40}>
                    <TitleSubtitle title={ section6["title"][lang]} left />
                    <p>{ section6["subtitle"][lang] }</p>
                    {/* <SlideInAnim direction="bottom">
                        <img className="p-6" src={require("../../image/drugomics/" + section6["image"])} />
                    </SlideInAnim> */}
                    <ColAutoFrame>
                        { devStepsSection6 }
                    </ColAutoFrame>
                </SpacingFrame>
            </MainFrame>

            <MainFrame>
                <SpacingFrame spacing={40}>
                    <TitleSubtitle title={ section7["title"][lang]} left />
                    <p>{ section7["subtitle"][lang] }</p>
                    {/* <SlideInAnim direction="bottom">
                        <img className="p-6" src={require("../../image/drugomics/" + section7["image"])} />
                    </SlideInAnim> */}
                    <ColAutoFrame>
                        { devStepsSection7 }
                    </ColAutoFrame>
                </SpacingFrame>
            </MainFrame>

            <div style={{ backgroundColor: "rgba(248, 249, 250, 1)"}}>
                <DisplayInfo2Cols contentData={section8["content"]} imagePath={imagePath} />
            </div>

            <MainFrame>
                <TitleSubtitle title={ section9["title"][lang] } />
                <SpacingFrame>
                    <TextBody text={ section9["content"]["text"][lang] } />
                    <p className="text-lg text-left font-semibold">{ section9["content"]["subtitle"][lang] }</p>
                    <LeftBorderCard data={ section9["content"][0] } isBulletList />
                </SpacingFrame>
            </MainFrame>

            <DisplayInfo2Cols contentData={section10["content"]} imagePath={imagePath} />
        
        </>

    )
};


export function DrugPipelineContentFibrosis() {
    const { lang } = useLang();

    //const data = typedUnitContent["Drug Design"]; 
    const data = typePipelineContent["fibrotic-disorders"];
    const section1 = data[1];
    
    return (

        <>
        
            <BannerSection image={pipelineBanner} title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } textPosition="Top" />

            <NavTree />

            <DisplayInfo2Cols contentData={section1["content"]} imagePath={imagePath} />
              
        </>

    )
};