import { useState, useEffect, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import dynamicIconImports from 'lucide-react/dynamicIconImports';
// import icons
import { ArrowUpRight, Paperclip, MapPin } from "lucide-react";
// import data
import { DataFlat, DataKeys } from '../../types';
// import components
import { UnitBadge } from "./Badges";
import { ImageZoom } from "./ImageFormat";
import { TextArrow, TextBulletList } from "./TextFormat";
// import function
import { LucideIconDynamic } from "../../functions/DisplayFunctions";
// import context
import { useLang } from '../Context';
import { SlideInAnim } from "../tools/Animations";


interface CardProps {
    data: DataKeys;
};
interface ImageCardProps extends CardProps {
    imagePath?: string;
};
interface ProductCardProps extends CardProps {
    imagePath?: string;
    haveTag?: boolean;
}
interface ToolCardProps extends CardProps {
    isBulletList?: boolean;
    width: number;
    height: number;
};
interface PositionsCardProps extends CardProps {
    link: string;
};
interface NewsBlogCardProps extends CardProps {
    imagePath: string;
    link: string;
};

export function ProductCard({ data, imagePath, haveTag } : ProductCardProps) {
    const [image, setImage] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [text, setText] = useState<string>("");
    const [link, setLink] = useState<string>("");
    const [tags, setTags] = useState<string[]>([]);
    const { lang } = useLang();

    useEffect(() => {
        if (data) {
            const src = require("../../" + imagePath + data["image"]);
            setImage(src);
            setTitle(data["title"][lang]);
            setText(data["text"][lang]);
            setTags(data["tags"]);
            let fixedLink = data["link"];
            if (lang === "Zh") {
                if (fixedLink.startsWith("/")) {
                    setLink("/tw" + fixedLink);
                } else {
                    setLink(fixedLink);
                };
            } else if (lang === "Jp") {
                if (fixedLink.startsWith("/")) {
                    setLink("/jp" + fixedLink);
                } else {
                    setLink(fixedLink);
                };
            } else {
                setLink(fixedLink)
            };
        }; 
    }, [data, lang]);

    let showBadges = tags.map((i) => {
        return (
            <UnitBadge label={i} />
        )
    });

    return (
        <Link to={link} className="group w-full h-full grid grid-flow-row auto-rows-min gap-2 cursor-pointer relative hover:text-teal-500 m-auto">
            <ImageZoom image={ image } />
            <TextArrow label={ title } />
            <p className="text-left text-grey-400 text-sm">{ text }</p>
            { haveTag &&
                <div className="flex flex-wrap gap-2 mt-2">
                    { showBadges }
                </div> 
            }
        </Link>
    )
};

export function SingleProductCard({ data, imagePath, haveTag } : ProductCardProps) {
    const [image, setImage] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [text, setText] = useState<string>("");
    const [link, setLink] = useState<string>("");
    const [tags, setTags] = useState<string[]>([]);
    const { lang } = useLang();

    useEffect(() => {
        if (data) {
            const src = require("../../" + imagePath + data["image"]);
            setImage(src);
            setTitle(data["title"][lang]);
            setText(data["text"][lang]);
            setTags(data["tags"]);
            let fixedLink = data["link"];
            if (lang === "Zh") {
                setLink("/tw" + fixedLink);
            } else if (lang === "Jp") {
                setLink("/jp" + fixedLink);
            } else {
                setLink(fixedLink)
            };
        }; 
    }, [data, lang]);

    let showBadges = tags.map((i) => {
        return (
            <UnitBadge label={i} />
        )
    });

    return (
        <Link to={link} className="group w-full h-full lg:grid lg:grid-cols-2 lg:grid-rows-1 lg:gap-8 sm:flex sm:flex-col sm:gap-2 cursor-pointer relative hover:text-teal-500 m-auto">
            <ImageZoom image={ image } />
            <div className="m-auto">
                <TextArrow label={ title } />
                <p className="text-left text-grey-400 text-sm">{ text }</p>
                { haveTag &&
                    <div className="flex flex-wrap gap-2 mt-3">
                        { showBadges }
                    </div>
                }
            </div>
        </Link>
    )
};


ToolCard.defaultProps = {
    width: 300,
    height: 330
};
export function ToolCard({ data, isBulletList, width, height } : ToolCardProps) {
    const [icon, setIcon] = useState<keyof typeof dynamicIconImports>("layers");
    const [title, setTitle] = useState<string>("");
    const [text, setText] = useState<string>("");
    const [bulletList, setBulletList] = useState<string[]>([]);
    const [link, setLink] = useState<string>("");
    const { lang } = useLang();

    useEffect(() => {
        if (data) {
            setIcon(data["icon"] as keyof typeof dynamicIconImports);
            setTitle(data["title"][lang]);
            if (isBulletList) {
                setBulletList(data["text"][lang]);
            } else {
                setText(data["text"][lang]);
            };
            let fixedLink = data["link"];
            if (lang === "Zh") {
                setLink("/tw" + fixedLink);
            } else if (lang === "Jp") {
                setLink("/jp" + fixedLink);
            } else {
                setLink(fixedLink)
            };
        }; 
    }, [data, lang]);
    

    return (
        <Link className="relative min-w-[240px] h-fit grid grid-flow-row auto-rows-min gap-6 bg-white p-6 rounded border-2 border-white
                        group hover:border-teal-500 hover:text-teal-500 hover:shadow-back transition-all duration-300 cursor-pointer"
            style={{ width: `${width}px`, minHeight: `${height}px` }}
            to={link}
        >
            <LucideIconDynamic name={ icon } width={32} height={32} />
            <div className="grid grid-flow-row auto-rows-auto gap-2">
                <p className="text-left text-lg font-bold group-hover:text-teal-500">{ title }</p>
                { isBulletList
                    ? <TextBulletList list={bulletList} textStyle="text-sm text-grey-400 group-hover:text-teal-500" />
                    : <p className="text-left text-sm text-grey-400 group-hover:text-teal-500">{ text }</p>
                }
            </div>
            <div className="absolute w-fit bottom-0 right-0 p-6">
                <ArrowUpRight size={25} />
            </div>
        </Link>
    )
};


export function BusinessUnitCard({ data, imagePath } : ImageCardProps) {
    const [image, setImage] = useState<string>("/image/Logo_core.svg");
    const [title, setTitle] = useState<string>("");
    const [text, setText] = useState<string>("");
    const [link, setLink] = useState<string>("");
    const { lang } = useLang();

    useEffect(() => {
        if (data) {
            const src = require("../../" + imagePath + data["image"]);
            setImage(src);
            setTitle(data["title"][lang]);
            setText(data["text"][lang]);
            let fixedLink = data["link"];
            if (lang === "Zh") {
                setLink("/tw/" + fixedLink);
            } else if (lang === "Jp") {
                setLink("/jp/" + fixedLink);
            } else {
                setLink(fixedLink)
            };
        }; 
    }, [data, lang]);

    return (
        <Link to={link} className="w-fit min-w-[250px] sm:w-[250px] h-fit min-h-[300px] grid grid-flow-row auto-rows-min gap-3 py-6 rounded-2xl m-auto
                                    group hover:bg-white transition-all duration-300 cursor-pointer">
            <img className="m-auto w-32" src={ image } alt="logo" />
            <div className="w-full h-min flex items-center justify-center gap-2 text-md">
                <p className="w-fit font-bold group-hover:text-teal-500">{ title }</p>
                <ArrowUpRight className="group-hover:text-teal-500" size={25} />
            </div>
            <p className="text-sm text-grey-400 group-hover:text-teal-500">{ text }</p>
        </Link>
    )
};


export function MemberCard({ data } : CardProps) {
    const [image, setImage] = useState<string>("cylin.jpg");
    const [name, setName] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const { lang } = useLang();

    useEffect(() => {
        if (data) {
            setImage(data["image"]);
            setName(data["name"][lang]);
            setTitle(data["title"][lang]);
            setDescription(data["description"][lang]);
        }; 
    }, [data, lang]);

    return (
        <div className="w-[280px] grid grid-flow-row auto-rows-min gap-4 p-3
                        hover:bg-white">
            <img className="h-[200px] w-full object-cover object-center select-none" src={require("../../image/people/" + image)} />
            <p className="text-left text-lg font-semibold">{ name }</p>
            <div className="text-sm flex flex-col gap-1">
                <p className="text-left text-grey-400 font-semibold">{ title }</p>
                <p className="text-left text-grey-400">{ description }</p>
            </div>
        </div>
    )
};


export function StepsCard({ data } : CardProps) {
    const [icon, setIcon] = useState<keyof typeof dynamicIconImports>("layers");
    const [step, setStep] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [text, setText] = useState<string>("");
    const { lang } = useLang();

    useEffect(() => {
        if (data) {
            setIcon(data["icon"] as keyof typeof dynamicIconImports);
            setStep(data["step"][lang]);
            setTitle(data["title"][lang]);
            setText(data["text"][lang])
        }; 
    }, [data, lang]);

    return (
        <div className="flex flex-col gap-4">
            <LucideIconDynamic name={ icon } width={32} height={32} />
            <p className="text-left text-lg font-bold">
                <span>{ step }:</span><br/>
                <span>{ title }</span>
            </p>
            <p className="text-left text-grey-400">{ text }</p>
        </div>
    )

};


export function StepsCardWithList({ data, imagePath } : ImageCardProps) {
    const [icon, setIcon] = useState<keyof typeof dynamicIconImports>("layers");
    const [image, setImage] = useState<string>("");
    const [step, setStep] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [text, setText] = useState<string>("");
    const [list, setList] = useState<string[]>([]);
    const { lang } = useLang();

    useEffect(() => {
        if (data) {
            setIcon(data["icon"] as keyof typeof dynamicIconImports);
            const src_image = require("../../" + imagePath + data["image"]);
            setImage(src_image);
            setStep(data["step"][lang]);
            setTitle(data["title"][lang]);
            setText(data["text"][lang])
            setList(data["list"][lang])
        }; 
    }, [data, lang]);

    return (
        <div className="flex flex-col gap-4">
            {/* <LucideIconDynamic name={ icon } width={32} height={32} /> */}
    
            <img className="p-6" src={image} />
            
            <p className="text-left text-lg font-bold">
                <span>{ step }:</span><br/>
                <span>{ title }</span>
            </p>
            <p className="text-left text-grey-400">{ text }</p>
            <TextBulletList list={list} /> 
        </div>
    )

};

export function OtherServicesCard({ data, imagePath } : ImageCardProps) {
    const [image, setImage] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [text, setText] = useState<string>("");
    const [link, setLink] = useState<string>("");
    const { lang } = useLang();

    useEffect(() => {
        if (data) {
            const src = require("../../" + imagePath + data["image"]);
            setImage(src);
            setTitle(data["title"][lang]);
            setText(data["text"][lang]);
            let fixedLink = data["absoluteLink"];
            if (lang === "Zh") {
                setLink("/tw" + fixedLink);
            } else if (lang === "Jp") {
                setLink("/jp" + fixedLink);
            } else {
                setLink(fixedLink)
            };
        }; 
    }, [data, lang]);

    return (
        <Link to={link} className="min-w-[240px] max-w-[460px] w-fit h-fit grid grid-cols-3 place-items-center gap-4 p-6 rounded-2xl m-auto
                        group hover:bg-white transition-all duration-300 cursor-pointer">
            <div className="col-span-1">
                <img className="w-full h-full" src={image} />
            </div>
            <div className="col-span-2 flex flex-col gap-2">
                <div className="w-fit flex items-center justify-center gap-2">
                    <p className="text-left text-lg font-semibold">{ title }</p>
                    <ArrowUpRight size={25} />
                </div>
                <p className="text-left text-sm text-grey-400">{ text }</p>
            </div>
        </Link>
    )
};


interface LeftBorderCardProps extends CardProps {
    isBulletList?: boolean;
};
export function LeftBorderCard({ data, isBulletList } : LeftBorderCardProps) {
    const { lang } = useLang();
    const [title, setTitle] = useState<string>("");
    const [text, setText] = useState<string>("");
    const [bulletList, setBulletList] = useState<string[]>([]);

    useEffect(() => {
        if (data) {
            setTitle(data["title"][lang]);
            if (isBulletList) {
                setBulletList(data["text"][lang]);
            } else {
                setText(data["text"][lang]);
            };
        }; 
    }, [data, lang]);


    return (
        <div className="min-w-[160px] w-fit h-fit flex flex-col gap-2 border-l-4 border-teal-300 px-4 leading-6">
            { title && <p className="text-left text-lg font-semibold">{ title }</p> }
            { isBulletList
                ? <TextBulletList list={bulletList} textStyle="text-left text-sm text-grey-400" />
                : <p className="text-left text-sm text-grey-400">{ text }</p>
            }
        </div>
    )
};


export function PositionCard({ data, link } : PositionsCardProps) {
    const [title, setTitle] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [text, setText] = useState<string>("");
    const [url, setUrl] = useState<string>("");
    const { lang } = useLang();


    useEffect(() => {
        if (data) {
            setTitle(data["Role"][lang]);
            setLocation(data["Location"][lang]);
            setText(data["Description"][lang]);
        }; 
    }, [data, lang]);

    useEffect(() => {
        if (lang === "Zh") {
            setUrl("/tw" + link);
        } else if (lang === "Jp") {
            setUrl("/jp" + link);
        } else {
            setUrl(link)
        };
    }, [link, lang]);
    

    return (
        <Link className="relative min-w-[240px] h-fit grid grid-flow-row auto-rows-min gap-6 bg-white p-6 rounded border-2 border-white
                        group hover:border-teal-500 hover:text-teal-500 hover:shadow-back transition-all duration-300 cursor-pointer"
            to={url}
        >
            <Paperclip width={32} height={32} />
            <div className="grid grid-flow-row auto-rows-auto gap-2 pb-6">
                <p className="text-left text-lg font-bold group-hover:text-teal-500">{ title }</p>
                <div className="flex gap-2">
                    <MapPin className="text-grey-400 group-hover:text-teal-500" size={20} />
                    <p className="text-left text-sm text-grey-400 group-hover:text-teal-500">{ location }</p>
                </div>
                <p className="text-left text-sm text-grey-400 group-hover:text-teal-500">{ text }</p>
            </div>
            <div className="absolute w-fit bottom-0 right-0 p-6">
                <ArrowUpRight size={25} />
            </div>
        </Link>
    )
};


export function BlogCard({ data, imagePath, link } : NewsBlogCardProps) {
    const [image, setImage] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [author, setAuthor] = useState<string>("");
    const [date, setDate] = useState<string>("");
    const [tags, setTags] = useState<string[]>([]);
    const [url, setUrl] = useState<string>("");
    const { lang } = useLang();

    useEffect(() => {
        if (data) {
            let src;
            if (data["image"] !== "") {
                src = require("../../" + imagePath + data["image"]);
            } else {
                src = require("../../" + imagePath + "lackImg.png");
            };
            setImage(src);
            setTitle(data["title"][lang]);
            setAuthor(data["author"][lang]);
            setDate(data["date"][lang]);
            setTags(data["tags"]);
        }; 
    }, [data, lang]);

    useEffect(() => {
        if (lang === "Zh") {
            setUrl("/tw" + link);
        } else if (lang === "Jp") {
            setUrl("/jp" + link);
        } else {
            setUrl(link)
        };
    }, [link, lang]);

    let showBadges = tags.map((i) => {
        return (
            <UnitBadge label={i} />
        )
    });

    return (
        <Link to={url} className="group w-full h-full grid grid-flow-row auto-rows-min gap-2 cursor-pointer relative hover:text-teal-500 m-auto">
            <ImageZoom image={ image } />
            <TextArrow label={ title } />
            <div className="flex flex-wrap gap-2 justify-between mt-2">
                <div className="flex flex-col">
                    <p className="text-left text-sm font-medium">{ author }</p>
                    <p className="text-left text-sm text-grey-400">{ date }</p>
                </div>
                <div className="h-fit flex items-center justify-center">
                    { showBadges }
                </div>
            </div> 
        </Link>
    )
};

export function MoreNewsCard({ data, imagePath, link } : NewsBlogCardProps) {
    const [image, setImage] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [author, setAuthor] = useState<string>("");
    const [date, setDate] = useState<string>("");
    const [url, setUrl] = useState<string>("");
    const { lang } = useLang();

    useEffect(() => {
        if (data) {
            let src;
            if (data["image"] !== "") {
                src = require("../../" + imagePath + data["image"]);
            } else {
                src = require("../../" + imagePath + "lackImg.png");
            };
            setImage(src);
            setTitle(data["title"][lang]);
            setAuthor(data["author"][lang]);
            setDate(data["date"][lang]);
        }; 
    }, [data, lang]);

    useEffect(() => {
        if (lang === "Zh") {
            setUrl("/tw" + link);
        } else if (lang === "Jp") {
            setUrl("/jp" + link);
        } else {
            setUrl(link)
        };
    }, [link, lang]);

    return (
        <Link to={url} className="group w-full h-full grid grid-flow-row auto-rows-min gap-2 cursor-pointer relative hover:text-teal-500 m-auto">
            <ImageZoom image={ image } />
            <TextArrow label={ title } />
            <p className="text-left text-sm text-grey-400">{ date } | { author }</p>
        </Link>
    )
};


export function NewsCard({ data, imagePath, link } : NewsBlogCardProps) {
    const { lang } = useLang();
    const [image, setImage] = useState<string>("");
    const [author, setAuthor] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [date, setDate] = useState<DataFlat>({});
    const [url, setUrl] = useState<string>("");

    useEffect(() => {
        if (data) {
            let src;
            if (data["image"] !== "") {
                src = require("../../" + imagePath + data["image"]);
            } else {
                src = require("../../" + imagePath + "lackImg.png");
            };
            setImage(src);
            setAuthor(data["author"][lang]);
            setTitle(data["title"][lang]);
            let dateString = data["date"]["En"];
            setDate(extractDateComponents(dateString));
        };
    }, [data, lang]);

    useEffect(() => {
        if (lang === "Zh") {
            setUrl("/tw" + link);
        } else if (lang === "Jp") {
            setUrl("/jp" + link);
        } else {
            setUrl(link)
        };
    }, [link, lang]);

    function extractDateComponents(dateString: string) {
        const date = new Date(dateString);
    
        if (isNaN(date.getTime())) {
            return { "day": "", "month": "", "year": "" };
        };
    
        const day = date.getDate(); 
        const month = date.toLocaleString("en-US", { month: "short" }); 
        const year = date.getFullYear(); 
    
        return { "day": day, "month": month, "year": year };
    };


    return (
        <Link to={url} className="group w-full flex lg:flex-row sm:flex-col gap-6 py-8 px-6 cursor-pointer hover:bg-teal-400">
            <div className="grid grid-flow-col auto-cols-max gap-6">
                <div className="w-fit flex flex-col gap-4">
                    <p className="text-xs text-nowrap group-hover:text-white">{ date["month"] } { date["year"] }</p>
                    <p className="text-xl font-semibold group-hover:text-white">{ date["day"] }</p>
                </div>
                <img className="w-[200px] h-[200px] object-cover bg-center flex-shrink-0" src={ image } />
            </div>
            <div className="w-full flex flex-col gap-4">
                <p className="text-left text-xs font-medium group-hover:text-white">{ author }</p>
                <div className="w-full h-max flex lg:flex-row sm:flex-col justify-between gap-2">
                    <p className="text-left text-lg font-semibold group-hover:text-white">{ title }</p>
                    <ArrowUpRight className="min-w-7 group-hover:text-white" size={24} />
                </div>
            </div>
        </Link>
    )
};